import React, {useState, useEffect, useContext,Fragment} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider} from '@mui/material';
import './InterviewApplicantPopup.css';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import { Splitscreen } from '@mui/icons-material';
// import { id } from 'date-fns/locale';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { setSeconds } from 'date-fns';

import {Link, useNavigate} from "react-router-dom";
import { NavLink } from 'react-router-dom';
import { id } from 'date-fns/locale';
import { UserContext } from './UserContext';
import { CircularProgress } from '@mui/material';
import { Autocomplete } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import WorkPreferences from './WorkPreferences';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import InputAdornment from '@mui/material/InputAdornment';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import TransgenderIcon from '@mui/icons-material/Transgender';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import WorkPreferencesInterviewApplicantUI from './WorkPreferencesInterviewApplicantUI';
import SchoolIcon from '@mui/icons-material/School';
import LanguageIcon from '@mui/icons-material/Language';
import TranslateIcon from '@mui/icons-material/Translate';
import PsychologyIcon from '@mui/icons-material/Psychology';
import HomeIcon from '@mui/icons-material/Home';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { calculateNewValue } from '@testing-library/user-event/dist/utils';

const InterviewApplicantPopup = (props) => {
   
  const style2 = {
    position: 'relative',
    margin:"auto",
    height: "auto",
    minWidth: 375,
    maxWidth: 450,
    scrollY:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    };
  
    const City = [
        { label: 'Bangalore' },
      ]  
      const Reference = [
        { label: 'Apna App' },
        { label: 'Linked In' },
        { label: 'Facebook' },
        { label: 'Naukri.com' },
        { label: 'Monster.com' },
        { label: 'Quickr.com' },
        { label: 'Walk-In' },
      ]  
    const label = { inputProps: { 'aria-label': 'Schedule Interview' } };
    const today=() => {
      var date = new Date();
      const day = (date.getDate() < 10 ? '0' : '')+date.getDate();
      const month = (date.getMonth() + 1 < 10 ? '0' : '')+(date.getMonth() + 1);
      const year = date.getFullYear();
      return year+'-'+month+'-'+day;
    }
  
  // const [interview, setInterview] = useState([]);
  const [OpenShedule, setOpenScedule ]= React.useState();
  const [searchParams] = useSearchParams();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsNumber,setWhatsNumber] = useState("");
  const [gender, setGender] = useState("");
  const [experience,setExperience]=useState();
  const [address, setAddress] = useState("");
  const [ Skills,setSkills]= useState("");
  const [jobId, setJobId] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [openApplicantPopup, setOpenApplicantPopup] = React.useState(false);
  const [resume, setResume ] = useState();
  const [reference, setReference]= useState("");
  const [Dob, setDob ]= React.useState(today());
  const [ExpYears, setExpYears]= useState(0);
  const [ExpMonth, setExpMonths]= useState(0);
  const [companyName, setCompanyName] = useState("");
  const {user} = useContext(UserContext);
  const [isLoading,setIsLoading]=useState(false);
  const [city ,setCity]= useState("");
  const [area,setArea] = useState("");
  const [scheduleInterview, setScheduleInterview] = useState(true);
  const [language, setLanguage ] = useState("");
  const [previousCurrent,setPreviousCurrent ]= useState(0);
  const [expectedSalary,setExpectedSalary ] = useState(0);
  const [voiceProcess,setVoiceProcess] = useState(true)
  const [nonVoiceProcess,setNonVoiceProcess] = useState(true)
  const [workFromHome,setWorkFromHome] = useState(true)
  const [workFromOffice,setWorkFromOffice] = useState(true)
  const [partTime,setPartTime] = useState(true)
  const [fullTime,setFullTime] = useState(true)
  const [nightShift,setNightShift] = useState(true)
  const [dayShift,setDayShift] = useState(true)
  const [companyTransportDay,setCompanyTransportDay] = useState(true)
  const [companyTransportNight,setCompanyTransportNight] = useState(true)
  const [ownTransport,setOwnTransport] = useState(true)
  const [willingToRelocate,setWillingToRelocate] = useState(true)
  const [jobApplications,setJobApplications] = useState([])
  const [education,setEducation] = useState("");
  const [resumeUrl,setResumeUrl] = useState("");
  const [applicantId,setApplicantId] = useState("");
  const [img,setImg]= useState("");
  const [profilePic,setProfilePic] = useState("")
  const [jobPreference,setJobPreference] = useState({});
  var navigate = useNavigate();
  
  
  useEffect(()=>{
    
    setOpenApplicantPopup(props.open)

    if(props.open)
      {
    
       if(props.applicantId){
        setId(props.applicantId)
  
        showApplicant()

       }
       

      }

  
    },[props.open])
    
    useEffect(()=>{

      props.applicantId && Promise.all(props.applicantId).then( ()=>{
        
        searchJobByApplicantId()
        
      });
         
        
      },[id])

    


      // useEffect(()=>{
        
       
      //  if(props.openApp)
      //    {
      //    setOpenApplicantPopup(props.openApp)
      //  if(props.id){
      //      setId(props.id)
      //      showApplicantDetails(props.id)
      //     }
           
      //   }
     
      //  },[props.openApp])
       
  
    const showApplicant = ()=>{
        if(props.applicantId){
        let apiURL=process.env.REACT_APP_API_URL + "/applicants/getapplicantdetails/" +props.applicantId
        let apiParams = {
            method:"GET",
        };
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
         
              // setJobPreference(repos.data && repos.data.preferences);
              setName(repos.data.name?repos.data.name:"")
              setMail(repos.data.email?repos.data.email:"")
              setMobile(repos.data.mobile?repos.data.mobile:"")
              setWhatsNumber(repos.data.number?repos.data.number:"")
              setDob(repos.data.dob?repos.data.dob:today())
              setGender(repos.data.gender?(repos.data.gender).toLowerCase():"")
              setExperience(repos.data.experience?repos.data.experience:"")
              setSkills(repos.data.skill?repos.data.skill:"")
              setAddress(repos.data.address?repos.data.address:"")
              setReference(repos.data.reference?repos.data.reference:"")
              setCity(repos.data.city?repos.data.city:"")
              setArea(repos.data.area?repos.data.area:"")
              setEducation(repos.data.education?repos.data.education:"")
              setLanguage(!repos.data.languages||repos.data.languages=="undefined"?"":repos.data.languages)
              setPreviousCurrent(!repos.data.currentSalary||repos.data.currentSalary=="undefined"?"":repos.data.currentSalary)
              setExpectedSalary(!repos.data.expectedSalary||repos.data.expectedSalary=="undefined"?"":repos.data.expectedSalary)
              setVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).voiceProcess:true)
              setNonVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).nonVoiceProcess:true)
              setWorkFromHome(repos.data.preferences?JSON.parse(repos.data.preferences).workFromHome:true)
              setWorkFromOffice(repos.data.preferences?JSON.parse(repos.data.preferences).workFromOffice:true)
              setPartTime(repos.data.preferences?JSON.parse(repos.data.preferences).partTime:true)
              setFullTime(repos.data.preferences?JSON.parse(repos.data.preferences).fullTime:true)
              setNightShift(repos.data.preferences?JSON.parse(repos.data.preferences).nightShift:true)
              setDayShift(repos.data.preferences?JSON.parse(repos.data.preferences).dayShift:true)
              setCompanyTransportDay(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportDay:true)
              setCompanyTransportNight(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportNight:true)
              setOwnTransport(repos.data.preferences?JSON.parse(repos.data.preferences).ownTransport:true)
              setWillingToRelocate(repos.data.preferences?JSON.parse(repos.data.preferences).willingToRelocate:true)
              if(repos.data.profilePicUrl && repos.data.profilePicUrl.length>50){
                setImg("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
                // console.log("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
                              }
              if(repos.data.resumeUrl && repos.data.resumeUrl.length>50){
                setResumeUrl(repos.data.resumeUrl)
              }
            }else props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
      }
    }

    const showApplicantDetails = (id)=>{
        
        let apiURL=process.env.REACT_APP_API_URL + "/applicants/getapplicantdetails/" +id;
        let apiParams = {
            method:"GET",
        };
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
            
              setName(repos.data.name?repos.data.name:"")
              setMail(repos.data.email?repos.data.email:"")
              setMobile(repos.data.mobile?repos.data.mobile:"")
              setWhatsNumber(repos.data.number?repos.data.number:"")
              setDob(repos.data.dob?repos.data.dob:today())
              setGender(repos.data.gender?(repos.data.gender).toLowerCase():"")
             setExperience(repos.data.experience?repos.data.experience:"")
              setSkills(repos.data.skill?repos.data.skill:"")
              setAddress(repos.data.address?repos.data.address:"")
              setReference(repos.data.reference?repos.data.reference:"")
              setCity(repos.data.city?repos.data.city:"")
              setArea(repos.data.area?repos.data.area:"")
              setEducation(repos.data.education?repos.data.education:"")
              setLanguage(!repos.data.languages||repos.data.languages=="undefined"?"":repos.data.languages)
              setPreviousCurrent(!repos.data.currentSalary||repos.data.currentSalary=="undefined"?"":repos.data.currentSalary)
              setExpectedSalary(!repos.data.expectedSalary||repos.data.expectedSalary=="undefined"?"":repos.data.expectedSalary)
              setVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).voiceProcess:true)
              setNonVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).nonVoiceProcess:true)
              setWorkFromHome(repos.data.preferences?JSON.parse(repos.data.preferences).workFromHome:true)
              setWorkFromOffice(repos.data.preferences?JSON.parse(repos.data.preferences).workFromOffice:true)
              setPartTime(repos.data.preferences?JSON.parse(repos.data.preferences).partTime:true)
              setFullTime(repos.data.preferences?JSON.parse(repos.data.preferences).fullTime:true)
              setNightShift(repos.data.preferences?JSON.parse(repos.data.preferences).nightShift:true)
              setDayShift(repos.data.preferences?JSON.parse(repos.data.preferences).dayShift:true)
              setCompanyTransportDay(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportDay:true)
              setCompanyTransportNight(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportNight:true)
              setOwnTransport(repos.data.preferences?JSON.parse(repos.data.preferences).ownTransport:true)
              setWillingToRelocate(repos.data.preferences?JSON.parse(repos.data.preferences).willingToRelocate:true)
              if(repos.data.profilePicUrl && repos.data.profilePicUrl.length>50){
setImg("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
// console.log("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
              }
            }else props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
      }



  const handleClose = () =>{
  
   if(props.open===true){
    props.close(jobId);
   }
    if(props.openApp===true){
      resetApplicant()
      props.closeApp();
    }
   
    setOpenApplicantPopup(false);
    
    }
  
    const resetApplicant = ()=>{
        setId("");
        setName("");
        setMail("");
        setMobile("");
        setWhatsNumber("");
        setDob(today());
        setAddress("");
        setCity("");
        setArea("");
        setSkills("");
        setGender("");
        setExpYears(0);
        setExpMonths(0);
        setReference("");
      }

//Upload Resume to Google Drive
      const UploadToDrive = async (applicantId) =>{
     
        var file = resume //the file
        var reader = new FileReader() //this for convert to Base64 
        reader.readAsDataURL(resume) //start conversion...
        reader.onload = function (e) { //.. once finished..
          var rawLog = reader.result.split(',')[1]; //extract only thee file data part
          var dataSend = { dataReq: { data: rawLog, name:file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
         fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
            { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
            .then(res => res.json()).then((data) => {
              //See response
             
              RetrieveResume(data,applicantId)
              props.showAlert("Resume Uploaded Successfully","success")
      
            }).catch(err => console.log(err)) // Or Error in console
        }
      
      }

      const RetrieveResume =async (data,applicantId)=>{
       
if(applicantId){
 
        let apiURL=process.env.REACT_APP_API_URL + "/applicants/saveResumeUrl/" + applicantId;
        let apiParams = {
            method:"POST",
            headers:{
              resumeurl:data.url,
              authtoken: localStorage.getItem("loggedintoken")
            }
      }
      fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
           
          // setResumeUrl(data.url)
          }
        })
        .catch(e => console.log(e)) 
  } 
 }


        const handleUpload = (e) =>{
        let formData = new FormData();
        formData.append("resume",e.target.files[0]);
        setResume(e.target.files[0]);
        // console.log(formData)
      }

      const checkduplicate =()=>{
        if(mobile.length>=10){
        let phone = mobile.toString().replace('-','').slice(-10);
        // console.log(phone)
        let apiURL=process.env.REACT_APP_API_URL + "/applicants/phone/" +phone;
          let apiParams = {
              method:"GET",
              
          };
           fetch(apiURL, apiParams)
          .then((res)=> (res.status===200 ? res.json() : res.text()))
          .then((repos)=> {
              if (repos.data) {
                if(user && user.accesstype== 5){
        let confirmation=window.confirm(repos.data.name+" registered with "+repos.data.mobile+". Do you want to create another registration with same number.")
        if(!confirmation){
          setMobile("")
        }else{
          return
        }}else{
          props.showAlert((repos.data.name+" registered with "+repos.data.mobile+" already!"),"error")
          setMobile("")
        }
              } 
          }) 
          .catch((err) => props.showAlert((''+err),"error"));
        }
      
        }


        //Upload Image To google Drive 


        const handleImageUpload = async (e) => {
          if (e.target.files[0].size > 71680) {
            //alert("Please choose a file with size below 70kb!");
            props.showAlert("Please choose a file with size below 70kb!","error")
            return;
          }
          handleFileSelect(e.target.files[0]);

          setProfilePic(e.target.files[0]);
         
        };

      //Upload Image
        const UploadImageToDrive = async (applicantId) =>{
     
          var file = profilePic //the file
          var reader = new FileReader() //this for convert to Base64 
          reader.readAsDataURL(profilePic) //start conversion...
          reader.onload = function (e) { //.. once finished..
            var rawLog = reader.result.split(',')[1]; //extract only thee file data part
            var dataSend = { dataReq: { data: rawLog, name:file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
           fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
              { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
              .then(res => res.json()).then((data) => {
                //See response
                
                 SaveProfilePic(data,applicantId)
                props.showAlert("Profile Pic Uploaded Successfully","success")
        
              }).catch(err => console.log(err)) // Or Error in console
          }
        
        }

  
      const SaveProfilePic = (data,applicantId)=>{
if(applicantId){

        let apiURL=process.env.REACT_APP_API_URL + "/applicants/saveProfileUrl/" + applicantId;
        let apiParams = {
            method:"POST",
            headers:{
              profilepicurl:data.url,
              authtoken: localStorage.getItem("loggedintoken")
            }
      }
      fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
           
        
          }
        })
        .catch(e => console.log(e)) 
  } 
      }


        function handleFileSelect(f) {
          var reader = new FileReader();
          // Closure to capture the file information.
          reader.onload = (function (theFile) {
            return function (e) {
              var binaryData = e.target.result;
              //Converting Binary Data to base 64
              var base64String = window.btoa(binaryData);
              //showing file converted to base64
              setImg("data:image/jpeg;base64," + base64String);
            };
          })(f);
          // Read in the image file as a data URL.
          reader.readAsBinaryString(f);
        }


        
 //functions
 const addApplicant=()=>{

  if (mobile.length === 0) {
   
    props.showAlert("Enter Mobile Number!!","error");
    return;
  }
  if (name.length === 0) {
      props.showAlert("Enter Name!!","error");
      return;
    }
    if (mail.length === 0) {
  
      props.showAlert("Enter Email!!","error");
      return;
    }
    if (gender.length === 0) {
    
      props.showAlert("Select Gender!!","error");
      return;
    }
  
   setIsLoading(true)

   let apiURL=process.env.REACT_APP_API_URL + "/applicants/addapplicant";
   let apiParams = {
       method:"POST",
       headers:{
           name: name,
           email: mail,
           mobile: mobile,
           number:whatsNumber,
           dob:Dob,
           city:city,
           area:area,
           education:education,
           preferences:JSON.stringify({
            voiceProcess:voiceProcess,
            nonVoiceProcess:nonVoiceProcess,
            workFromHome:workFromHome,
            workFromOffice:workFromOffice,
            partTime:partTime,
            fullTime:fullTime,
            nightShift:nightShift,
            dayShift:dayShift,
            companyTransportDay:companyTransportDay,
            companyTransportNight:companyTransportNight,
            ownTransport:ownTransport,
            willingToRelocate:willingToRelocate,
            }),
           languages:((language && language.length>0 && language.lastIndexOf(",")===language.length-1)?language.substring(0,language.length-1):language),
           currentsalary:previousCurrent,
           expectedsalary:expectedSalary,
           experience:(parseInt(ExpYears)*12)+parseInt(ExpMonth),
           skill:encodeURIComponent(Skills),
           gender: gender,
           jobid: jobId,
           jobtitle: jobTitle,
           companyname:companyName,
           address: encodeURIComponent(address),
           reference:user && user.accesstype !== "c"?encodeURIComponent(reference):"Self Registered",
           createdbyid:user?user._id:"Candidate",
           createdbyname:user?user.name:"Candidate",
           authtoken: localStorage.getItem("loggedintoken")
       },
      // body: resume,
   };
   // console.log(apiParams)
   fetch(apiURL, apiParams)
   .then((res)=> (res.status===200 ? res.json() : res.text()))
   .then((repos)=> {
       if (repos.data) {
  
        setApplicantId(repos.data._id)
        profilePic && UploadImageToDrive(repos.data._id)
        resume && UploadToDrive(repos.data._id)
       
      
        
        setOpenApplicantPopup(false)
         setIsLoading(false)
         props.showAlert("Applicant Added Successfully","success")
        // alert("Applicant Added Successfull");
         if(user && scheduleInterview ){
           setTimeout(()=>{
             window.location.href="/jobs?applicantid="+repos.data._id;
           },1000)
         }
         else{
           setTimeout(()=>{
             sendEmailToCandidate(repos.data._id)
             window.location.reload(false);
           },1500)
         }
          
       }else{ props.showAlert(""+(repos.message || repos),"error");
       setIsLoading(false)}
   }) 
   .catch((err) =>{props.showAlert((''+err),"error")
   setIsLoading(false)})
 }

 const sendEmailToCandidate = ()=>{
  if(mail){
  let apiURL=process.env.REACT_APP_API_URL + "/users/sendemailtoapplicant";
  let apiParams = {
    method:"POST",
    headers:{
        email:mail,
        name:name,
     }
    };
   
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.message) {
       
          props.showAlert("Email Has been sent Successfully","success")
          
        }else{ props.showAlert(""+(repos.message || repos),"error");
        setIsLoading(false)
      }
  
    }) 
    .catch((err) =>{props.showAlert((''+err),"error")
    setIsLoading(false)})
  }
 }


 
 
 const searchJobByApplicantId = async ()=>{
if(id)
{
    let apiURL = process.env.REACT_APP_API_URL +"/interview/getjobsbyapplicantid/"+ id;
  
    let apiParams = {
      method:"GET",
      authtoken: localStorage.getItem("loggedintoken")
  };
  await fetch(apiURL, apiParams)
 .then((res)=> (res.status===200 ? res.json() : res.text()))
 .then((repos)=> {
     if (repos.data) {
      setJobId(repos.data[0] && repos.data[0]._id.jobId)
      setJobTitle(repos.data[0] && repos.data[0]._id.jobtitle)

      selectedJobPreference(repos.data[0]._id.jobId)
      setJobApplications(repos.data)

      
     }else {
      props.showAlert(""+(repos.message || repos),"error");  
     }
 }) 
 .catch((err) => props.showAlert((''+err),"error"));}
}

const setPreference=(key,value)=>
{
switch (key) {
case "voiceProcess":
  setVoiceProcess(value)
  break;
case "nonVoiceProcess":
  setNonVoiceProcess(value)
  break;
case "workFromHome":
  setWorkFromHome(value)
  break;
case "workFromOffice":
  setWorkFromOffice(value)
  break;
  case "partTime":
    setPartTime(value)
    break;
  case "fullTime":
    setFullTime(value)
    break;
  case "nightShift":
    setNightShift(value)
    break;
  case "dayShift":
    setDayShift(value)
    break;
    case "companyTransportDay":
      setCompanyTransportDay(value)
      break;
      case "companyTransportNight":
        setCompanyTransportNight(value)
        break;
    case "ownTransport":
      setOwnTransport(value)
      break;
default:
  break;
}
}

const selectedJobPreference = (jobId)=>{
  if(jobId && jobId !== "new"){
  let apiURL=process.env.REACT_APP_API_URL + "/jobs/getJobpreferences/"+jobId;
  let apiParams = {
    method:"GET",
    headers:{
      authtoken: localStorage.getItem("loggedintoken")
    }
    };
   
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
      
       setJobPreference(JSON.parse(repos.data.preferences))
         
          
        }else{ props.showAlert(""+(repos.message || repos),"error");
        setIsLoading(false)
      }
  
    }) 
    .catch((err) =>{props.showAlert((''+err),"error")
    setIsLoading(false)
  })}
  else if(jobId && jobId === "new"){
    setJobPreference(JSON.parse("{\"voiceProcess\":false,\"nonVoiceProcess\":false,\"workFromHome\":false,\"workFromOffice\":false,\"partTime\":false,\"fullTime\":false,\"nightShift\":false,\"dayShift\":false,\"ownTransport\":false}"))
  }
}

//edit applicant
const editApplicantDetails=(id)=>{
    setIsLoading(true)
    let apiURL=process.env.REACT_APP_API_URL + "/applicants/editapplicant/"+id;
    let apiParams = {
        method:"POST",
        headers:{
            name: name,
            email: mail,
            mobile: mobile,
            number:whatsNumber,
            dob:Dob,
            city:city,
            area:area,
            education:education,
            preferences:JSON.stringify({
              voiceProcess:voiceProcess,
              nonVoiceProcess:nonVoiceProcess,
              workFromHome:workFromHome,
              workFromOffice:workFromOffice,
              partTime:partTime,
              fullTime:fullTime,
              nightShift:nightShift,
              dayShift:dayShift,
              companyTransportDay:companyTransportDay,
              companyTransportNight:companyTransportNight,
              ownTransport:ownTransport,
              willingToRelocate:willingToRelocate,
              }),
            languages:((language && language.length>0 && language.lastIndexOf(",")===language.length-1)?language.substring(0,language.length-1):language),
            currentsalary:previousCurrent,
            expectedsalary:expectedSalary,
            experience:(parseInt(ExpYears)*12)+parseInt(ExpMonth),
            skill:encodeURIComponent(Skills),
            gender: gender,
            address: encodeURIComponent(address),
            reference:encodeURIComponent(reference),
            updatedById:user?user._id:"Candidate",
            updatedByName:user?user.name:"Candidate",
            authtoken: localStorage.getItem("loggedintoken")
  
        }
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          setOpenApplicantPopup(false)
          setIsLoading(false)
         
          profilePic && UploadImageToDrive(id)
          props.showAlert("Applicant Updated Successfully","success")
          if(user && scheduleInterview ){
            setTimeout(()=>{
              window.location.href="/jobs?applicantid="+repos.data._id;
            },1000)
          }
          // setTimeout(()=>{
          //   if(props.applicantId){
          //     setId(props.applicantId)
        
          //     showApplicant()
      
          //    }else if(props.id){
          //     setId(props.id)
          //     showApplicantDetails(props.id)
          //    }
       
          // },2000)
          
        }else {
          props.showAlert(""+(repos.message || repos),"error");
            setIsLoading(false)
        }
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }

  const selectInterview = ()=>{
    if(jobId==="new"){
    
      window.location.href="/jobs?applicantid="+id;
    
    }else{
    
        props.close(jobId)
    }
     }

  const updateLanguage = (lang) =>{

    var newLanguageValue = (((language.length>0 && language.lastIndexOf(",")===language.length-1)?language.substring(0,language.length-1):language)+lang);
    setLanguage(((newLanguageValue.length>0 && newLanguageValue.indexOf(",")===0)?newLanguageValue.substring(1,newLanguageValue.length-1):newLanguageValue));
    }

    const backtoLogin = ()=>{
      navigate("/");
    }


  return (
   <div>
   <Modal
    open={openApplicantPopup}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    sx={{overflow:'scroll',textAlign:"center"}}
    >
	<div id="applicant_details">
	<img id="n_291450_dv" src="n_291450_dv.png" srcset="n_291450_dv.png 1x, n_291450_dv@2x.png 2x" alt='reload' />
		
	<img id="n_7973908_dw" src="n_7973908_dw.png" srcset="n_7973908_dw.png 1x, n_7973908_dw@2x.png 2x" alt='reload' />
  <svg class="schedule_path_Pointer">
		<ellipse onClick={()=>selectInterview()} id="schedule_path_Pointer" rx="7" ry="7" cx="7" cy="7">
		</ellipse>
	</svg>
	<svg class="Line_27" viewBox="0 0 234 1">
		<path id="Line_27" d="M 0 0 L 234 0">
		</path>
	</svg>
	<svg class="Applicant_Path_Pointer">
		<ellipse id="Applicant_Path_Pointer" rx="7" ry="7" cx="7" cy="7">
		</ellipse>
	</svg>
	<img onClick={()=>selectInterview()} id="scheduleInterview_Page" src="calendar1_f.png" srcset="calendar1_f.png 1x, calendar1_f@2x.png 2x"/>
		
	<img id="user4_f" src="user4_f.png" srcset="user4_f.png 1x, user4_f@2x.png 2x" />
		
	<svg class="Border_ApplicantPopup">
		<rect id="Border_ApplicantPopup" rx="0" ry="0" x="0" y="0" width="539" height="85">
		</rect>
	</svg>
	<svg class="Name_Border">
		<linearGradient id="Name_Border" spreadMethod="pad" x1="0.436" x2="-0.378" y1="1" y2="-1.052">
			<stop offset="0" stop-color="#fff" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#808080" stop-opacity="1"></stop>
		</linearGradient>
		<rect id="Name_Border" rx="17" ry="17" x="0" y="0" width="400" height="65">
		</rect>
	</svg>
	<div id="Username_App_gc">
		<span><marquee>{name}</marquee></span>
	</div>
	<div id="contact_Number_App">
		<span>{<a href={"tel:"+mobile}>{mobile}</a>}</span>
	</div>

  {(props.close || props.closeApp)?<div className='Applicant_CancelBtn'>
  <Button title="Close" id='Applicant_CancelBtn' size="small" variant="Standard" sx={{ bgcolor: "white",float:"right",right:0,top:0,position:"absolute",height:28,width:5 }} onClick={()=>handleClose()} > <CloseIcon style={{height:"25px",width:"25px"}} /> </Button>
  </div>:""}

  <div className='Applied_Job_app'>
  <FormControl  variant="outlined" sx={{width: 260 }} style={{marginTop:"10px"}} size="small">
        <InputLabel >Applied Job</InputLabel>
        <Select
        
          labelId="appliedJobLabel"
          id="Applied_Job_app"
          
          value={jobId||"new"} onChange={(e)=> {
           
            setJobId(e.target.value); 
            selectedJobPreference(e.target.value);
            setJobTitle(jobApplications.filter(job=>job.jobId===e.target.value)[0].jobtitle); 
            
           
           } } > 
          {
           jobApplications && jobApplications.map(job=>  <MenuItem value={job._id.jobId}>{job._id.companyname + (job._id.officeName && " - "+job._id.officeName)}:{job._id.jobtitle}</MenuItem>
          
           )
        }
        <MenuItem value="new">Add New Interview</MenuItem>
        </Select> 
      </FormControl>
</div>
	
	<div className='applicant_name_app'>
  <TextField  id="applicant_name_app"  sx={{ width: 257 }}  size="small" label="Name" variant="outlined"  value={name || ""} onChange={(e)=> {
      setName(e.target.value);
  }}/>
  </div>
  
	<div className='applicant_Mobile_app'>
  <TextField id="applicant_Mobile_app"  sx={{ width: 259,margin:"2px"}}  type="number" size="small" label="Mobile No" variant="outlined"  value={mobile} onBlur={()=>checkduplicate()}
       onChange={(e)=> {
        setMobile(e.target.value);  
    }}/>
  </div>
  {/* <svg class="Rectangle_49">
		<rect id="Rectangle_49" rx="10" ry="10" x="0" y="0" width="125" height="53">
		</rect>
	</svg> */}
  <div className='applicant_gender_app'>
  <FormControl variant="outlined" sx={{minWidth: 125}} size="small" >
    <InputLabel id="applicant_gender_app">Gender</InputLabel>
    <Select
      labelId="demo-simple-select-labell"
      id="applicant_gender_app"
      sx={{minWidth: 125}}
      value={gender} onChange={(e)=> {
      
        setGender(e.target.value);   

       } } >
         <MenuItem selected disabled>
       
      </MenuItem>
    
      <MenuItem value="male">Male</MenuItem>
      <MenuItem value="female">Female</MenuItem>
      <MenuItem value="others">Others</MenuItem>
    </Select>
  </FormControl> 
</div>

<div className='applicant_DOB_app'>
  
       <input type='date'
       id="applicant_DOB_app"
      style={{width: "125px" }}
      label="DOB"
      value={Dob}
      onChange={(e) => {
      setDob(e.target.value);
    }}
    />

</div>
	
  <div className='applicant_wht_app'>
  <TextField  id="applicant_wht_app" sx={{width: 257}}  size="small" label="Whatsapp No" variant="outlined"  value={whatsNumber} onChange={(e)=> {
        setWhatsNumber(e.target.value)  }}/>
  </div>
 
  <div className='applicant_email_app'>
  <TextField id="applicant_email_app" sx={{width: 257 }} size="small" label="email" variant="outlined"  value={mail || ""} onChange={(e)=> {
    setMail(e.target.value);     
  }}/>
  </div>
  <div id="Experience_app">
		<span>Experience</span>
	</div>
	<svg class="Line_14" viewBox="0 0 71 0.5">
		<path id="Line_14" d="M 0 0.5 L 71 0">
		</path>
	</svg>

  <div className='applicant_exp_years'>
  <TextField id='applicant_exp_years'  sx={{ m: 0.5, width: '120' }} label="Years" variant="outlined" size="small" value={ExpYears} onChange={(e)=> {
      setExpYears(e.target.value);
    }}/>

  </div>

   <div className='applicant_exp_months'>
   <TextField id='applicant_exp_months' sx={{ m:0.5, width: '120' }} label="Months" variant="outlined" size="small" value={ExpMonth} onChange={(e)=> {
      
      setExpMonths(e.target.value);
    }}/>
   </div>


	{/* <svg class="Rectangle_47">
		<rect id="Rectangle_47" rx="11" ry="11" x="0" y="0" width="263" height="38">
		</rect>
	</svg> */}
  <div className='applicant_language_app'>
  <TextField 
      sx={{width: "300px",marginTop:"2px", }}  id="applicant_language_app"  size="small" label="Languages" variant="outlined"  value={language} onChange={(e)=> {
          setLanguage(e.target.value.length>0&&e.target.value.slice(0,1)===","?language.substring(1,e.target.value.length):e.target.value);
 }} /> 
       <div style={{textAlign:"center",fontSize:"small",marginTop:"3px"}}>
      
    {(language||"").includes("English")?"":  <button style={{margin:"2px",border:"1px solid",padding:"2px 6px"}} onClick={e=>updateLanguage(",English,")}>English</button>}
    {(language||"").includes("Hindi")?"":   <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Hindi,")}>Hindi</button>}
    {(language||"").includes("Kannada")?"":   <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Kannada,")}>Kannada</button>}
    {(language||"").includes("Urdu")?"":   <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Urdu,")}>Urdu</button>}
    {(language||"").includes("Tamil")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Tamil,")}>Tamil</button>}
    {(language||"").includes("Telugu")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Telugu,")}>Telugu</button>}
    {(language||"").includes("Malayalam")?"":     <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Malayalam,")}>Malayalam</button>}
    {(language||"").includes("Marathi")?"":     <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Marathi,")}>Marathi</button>}
    {(language||"").includes("Punjabi")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Punjabi,")}>Punjabi</button>}
    {(language||"").includes("Bangla")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Bangla,")}>Bangla</button>}
    {(language||"").includes("Arabic")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Arabic,")}>Arabic</button>}
    {(language||"").includes("French")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",French,")}>French</button>}
    {(language||"").includes("Spanish")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Spanish,")}>Spanish</button>}
    {(language||"").includes("Russian")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Russian,")}>Russian</button>}
    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setLanguage("")}>Clear</button>
      <br/></div> 
  </div>

	<div className="applicant_education_app">
  <TextField
      sx={{width: "280px",marginTop:"2px", }}  id="applicant_education_app"  size="small" label="Education" variant="outlined"  value={education} onChange={(e)=> {
      setEducation(e.target.value)

       }} /> 
  </div>

    <div className='applicant_current'>
    <TextField
         sx={{width: "125px",margin:"2px" }}  id="applicant_current"  size="small" label="Current Salary" variant="outlined"  value={previousCurrent} onChange={(e)=> {
         setPreviousCurrent(e.target.value)
     }} />
    </div>

  <div className='applicant_expsalary_'>
  <TextField 
    sx={{width: "125px",margin:"2px" }}  id="applicant_expsalary_"  size="small" label="Expected Salary" variant="outlined"  value={expectedSalary} onChange={(e)=> {
         setExpectedSalary(e.target.value)
 }} /> 
  </div>

  <div className='applicant_city_app'>
  <FormControl variant="outlined" sx={{minWidth: 260,margin:"2px"}} size="small" >
    <Autocomplete
      disablePortal
      id="applicant_city_app"
      options={City}
      value={city || ""}
      onChange={(event,value)=>setCity(value.label)}
      renderInput={(params) => <TextField {...params}  
          sx={{width: 260,margin:"2px"}}
          size="small" label="City" 
          value={city || ""}
          onChange={(e)=> {
            setCity(e.target.value);
          }}  />}
         /> 
         </FormControl>
  </div>
 

  <div className='applicant_area_app'>
  <TextField  id="applicant_area_app" sx={{width: 260,margin:"2px"}}  size="small" label="Area" variant="outlined"  value={area} onChange={(e)=> {
            setArea(e.target.value)  }}/>
  </div>
  
  

    <div className='applicant_address_app'>
    <TextField
      id="applicant_address_app"
      sx={{width: 280 }}
      size="small"
      label="Address"
      multiline
      rows={2} value={address || ""} 
      onChange={(e)=> {
      setAddress(e.target.value);
       }} />
    </div>

	<svg class="Applicant_path" viewBox="13.27 153.556 393.995 2106.058">
		<path id="Applicant_path" d="M 332.8991394042969 189.6371459960938 L 346.9927978515625 153.5560607910156 L 361.6285705566406 189.6371459960938 L 405.8486938476562 189.6371459960938 L 407.2652893066406 2259.6142578125 L 17.59573364257812 2258.017822265625 L 13.27041721343994 189.6371459960938 L 28.01298904418945 189.6371459960938 L 332.8991394042969 189.6371459960938">
		</path>
	</svg>
	

	<div className='applicant_skill_app'>
  <TextField  id="applicant_skill_app" multiline rows={2}  sx={{width: 280 }} size="small" label="Skills" variant="outlined"  value={Skills || ""} onChange={(e)=> {
    setSkills(e.target.value);     
  }}/>
  </div>
	
	
	<div id="Preferences_app">
		<span>Preferences</span>
	</div>

	<WorkPreferencesInterviewApplicantUI  preferences={{voiceProcess,nonVoiceProcess,fullTime,partTime,workFromOffice,workFromHome,dayShift,nightShift,companyTransportDay,companyTransportNight,ownTransport}} highlight={{jobPreference}} setPreference={(key,value)=>setPreference(key,value)}/>

  <div className='Willing_to_Relocate_Preference_app'>
  <input type="checkbox" id="Willing_to_Relocate_Preference_app" name="Willing_to_Relocate_Preference" checked={willingToRelocate} onChange={()=>setWillingToRelocate(!willingToRelocate)}/>
  </div>
  <label id='Willing_to_Relocate_lbl_app_' for="chkWillingtorelocate" style={{color:willingToRelocate?"red":"black"}}>Willing To Relocate</label>

  <div className='applicant_reference_app'>
  <Autocomplete
  disablePortal
  id="applicant_reference_app"
  freeSolo
  options={Reference}
  sx={{ width: 280}}
  value={reference || ""}
  onChange={(event,value)=>setReference(value.label)}
  renderInput={(params) => <TextField {...params}  
    
      sx={{minWidth: 280 }}
      size="small" label="Reference"
      value={reference || ""}
      onChange={(e)=> {
        setReference(e.target.value); 
      }}  />}
     />
       
  </div> 


	
	
	{/* <svg class="Rectangle_74">
		<rect id="Rectangle_74" rx="0" ry="0" x="0" y="0" width="23" height="21">
		</rect>
	</svg> */}
  {user?  <div className='applicant_check_schedule_intw'>
 <Checkbox {...label} id="applicant_check_schedule_intw" checked={scheduleInterview} onChange={(e)=>setScheduleInterview(e.target.checked)} />
  </div>:""}

	<div id="Schedule_Interview_app_">
		<span>Schedule Interview</span>
	</div>


	{/* <svg class="Rectangle_75">
		<rect id="Rectangle_75" rx="11" ry="11" x="0" y="0" width="119" height="53">
		</rect>
	</svg>*/}
  <div className='Edit_btn_app'>
 
 <Button disabled={isLoading} id="Edit_btn_app" type="reset" title={"Edit Add "}  variant="contained" onClick={()=>id.length>0?editApplicantDetails(id):addApplicant()}>{id.length>0?"Edit ":"Add Applicant "}</Button> &nbsp; &nbsp;
 &nbsp; &nbsp;{isLoading ? <CircularProgress/>:""}
</div>
	{/*<div id="EDIT">
		<span>EDIT</span>
	</div> */}
</div>
</Modal>
</div>
  )
  
}

export default InterviewApplicantPopup
