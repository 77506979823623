import React, {useState, useEffect, useContext,Fragment} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider} from '@mui/material';
import './ApplicantPopupUI.css';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import CheckIcon from '@mui/icons-material/Check';
import ScheduleInterview from './ScheduleInterview';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
// import DatePicker from '@mui/lab/DatePicker'; 
// import TimePicker from '@mui/lab/TimePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import { Splitscreen } from '@mui/icons-material';
// import { id } from 'date-fns/locale';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { setSeconds } from 'date-fns';
import { NavLink } from 'react-router-dom';

import { id } from 'date-fns/locale';
import { UserContext } from './UserContext';
import { CircularProgress } from '@mui/material';
import { Autocomplete } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import WorkPreferences from './WorkPreferences';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import InputAdornment from '@mui/material/InputAdornment';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import TransgenderIcon from '@mui/icons-material/Transgender';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import WorkPreferencesUI from './WorkPreferencesUI';
import SchoolIcon from '@mui/icons-material/School';
import LanguageIcon from '@mui/icons-material/Language';
import TranslateIcon from '@mui/icons-material/Translate';
import PsychologyIcon from '@mui/icons-material/Psychology';
import HomeIcon from '@mui/icons-material/Home';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { calculateNewValue } from '@testing-library/user-event/dist/utils';
import RegisterUI from './RegisterUI'
import {  useMediaQuery,useTheme, } from '@mui/material'
import CandidateApplcationsTopBar from './PC/CandidateApplicationsTopBar';
import CandidateProfileTopBar from './PC/CandidateProfileTopBar';

const ApplicantPopupUI = (props) => {
   
  const theme = useTheme();
  const isMatched = useMediaQuery(theme.breakpoints.down('lg'))
  const style2 = {
    position: 'relative',
    margin:"auto",
    height: "auto",
    minWidth: 375,
    maxWidth: 450,
    scrollY:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    };
  
    const City = [
        { label: 'Bangalore' },
      ]  
      const Reference = [
        { label: 'Apna App' },
        { label: 'Linked In' },
        { label: 'Facebook' },
        { label: 'Naukri.com' },
        { label: 'Monster.com' },
        { label: 'Quickr.com' },
        { label: 'Walk-In' },
      ]  
    const label = { inputProps: { 'aria-label': 'Schedule Interview' } };
  
  // const [interview, setInterview] = useState([]);
  const [OpenShedule, setOpenScedule ]= React.useState();
  const [searchParams] = useSearchParams();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsNumber,setWhatsNumber] = useState("");
  const [gender, setGender] = useState("");
  const [experience,setExperience]=useState();
  const [address, setAddress] = useState("");
  const [ Skills,setSkills]= useState("");
  const [jobId, setJobId] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [openApplicantPopup, setOpenApplicantPopup] = React.useState(false);
  const [resume, setResume ] = useState();
  const [reference, setReference]= useState("");
  const [Dob, setDob ]= React.useState(new Date());
  const [ExpYears, setExpYears]= useState(0);
  const [ExpMonth, setExpMonths]= useState(0);
  const [companyName, setCompanyName] = useState("");
  const {user} = useContext(UserContext);
  const [isLoading,setIsLoading]=useState(false);
  const [city ,setCity]= useState("");
  const [area,setArea] = useState("");
  const [scheduleInterview, setScheduleInterview] = useState(true);
  const [language, setLanguage ] = useState("");
  const [previousCurrent,setPreviousCurrent ]= useState(0);
  const [expectedSalary,setExpectedSalary ] = useState(0);
  const [voiceProcess,setVoiceProcess] = useState(true)
  const [nonVoiceProcess,setNonVoiceProcess] = useState(true)
  const [workFromHome,setWorkFromHome] = useState(true)
  const [workFromOffice,setWorkFromOffice] = useState(true)
  const [partTime,setPartTime] = useState(true)
  const [fullTime,setFullTime] = useState(true)
  const [nightShift,setNightShift] = useState(true)
  const [dayShift,setDayShift] = useState(true)
  const [companyTransportDay,setCompanyTransportDay] = useState(true)
  const [companyTransportNight,setCompanyTransportNight] = useState(true)
  const [ownTransport,setOwnTransport] = useState(true)
  const [willingToRelocate,setWillingToRelocate] = useState(true)
  const [jobApplications,setJobApplications] = useState([])
  const [education,setEducation] = useState("");
  const [resumeUrl,setResumeUrl] = useState("");
  const [applicantId,setApplicantId] = useState("");
  const [img,setImg]= useState("");
  const [profilePic,setProfilePic] = useState("")

  
  useEffect(()=>{
    
    setOpenApplicantPopup(props.open)

    if(props.open)
      {
       if(props.applicantId){
        setId(props.applicantId)
  
        showApplicant()
       }

      }

  
    },[props.open])
    
    useEffect(()=>{

      props.applicantId && Promise.all(props.applicantId).then( ()=>{
        
        searchJobByApplicantId()
        
      });
         
        
      },[id])


      useEffect(()=>{
        
       
       if(props.openApp)
         {
         setOpenApplicantPopup(props.openApp)
       if(props.id){
           setId(props.id)
           showApplicantDetails(props.id)
          }
           
        }
     
       },[props.openApp])
       
  
    const showApplicant = ()=>{
        if(props.applicantId){
        let apiURL=process.env.REACT_APP_API_URL + "/applicants/getapplicantdetails/" +props.applicantId
        let apiParams = {
            method:"GET",
        };
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              setName(repos.data.name?repos.data.name:"")
              setMail(repos.data.email?repos.data.email:"")
              setMobile(repos.data.mobile?repos.data.mobile:"")
              setWhatsNumber(repos.data.number?repos.data.number:"")
              setDob(repos.data.dob?repos.data.dob:new Date())
              setGender(repos.data.gender)
              setExperience(repos.data.experience?repos.data.experience:"")
              setSkills(repos.data.skill?repos.data.skill:"")
              setAddress(repos.data.address?repos.data.address:"")
              setReference(repos.data.reference?repos.data.reference:"")
              setCity(repos.data.city?repos.data.city:"")
              setArea(repos.data.area?repos.data.area:"")
              setEducation(repos.data.education?repos.data.education:"")
              setLanguage(!repos.data.languages||repos.data.languages=="undefined"?"":repos.data.languages)
              setPreviousCurrent(!repos.data.currentSalary||repos.data.currentSalary=="undefined"?"":repos.data.currentSalary)
              setExpectedSalary(!repos.data.expectedSalary||repos.data.expectedSalary=="undefined"?"":repos.data.expectedSalary)
              setVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).voiceProcess:true)
              setNonVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).nonVoiceProcess:true)
              setWorkFromHome(repos.data.preferences?JSON.parse(repos.data.preferences).workFromHome:true)
              setWorkFromOffice(repos.data.preferences?JSON.parse(repos.data.preferences).workFromOffice:true)
              setPartTime(repos.data.preferences?JSON.parse(repos.data.preferences).partTime:true)
              setFullTime(repos.data.preferences?JSON.parse(repos.data.preferences).fullTime:true)
              setNightShift(repos.data.preferences?JSON.parse(repos.data.preferences).nightShift:true)
              setDayShift(repos.data.preferences?JSON.parse(repos.data.preferences).dayShift:true)
              setCompanyTransportDay(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportDay:true)
              setCompanyTransportNight(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportNight:true)
              setOwnTransport(repos.data.preferences?JSON.parse(repos.data.preferences).ownTransport:true)
              setWillingToRelocate(repos.data.preferences?JSON.parse(repos.data.preferences).willingToRelocate:true)
              if(repos.data.profilePicUrl && repos.data.profilePicUrl.length>50){
                setImg("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
                // console.log("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
                              }
              if(repos.data.resumeUrl && repos.data.resumeUrl.length>50){
                setResumeUrl(repos.data.resumeUrl)
              }
            }else props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
      }
    }

    const showApplicantDetails = (id)=>{
        
        let apiURL=process.env.REACT_APP_API_URL + "/applicants/getapplicantdetails/" +id;
        let apiParams = {
            method:"GET",
        };
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
            // console.log(repos.data)
              setName(repos.data.name?repos.data.name:"")
              setMail(repos.data.email?repos.data.email:"")
              setMobile(repos.data.mobile?repos.data.mobile:"")
              setWhatsNumber(repos.data.number?repos.data.number:"")
              setDob(repos.data.dob?repos.data.dob:new Date())
              setGender(repos.data.gender)
             setExperience(repos.data.experience?repos.data.experience:"")
              setSkills(repos.data.skill?repos.data.skill:"")
              setAddress(repos.data.address?repos.data.address:"")
              setReference(repos.data.reference?repos.data.reference:"")
              setCity(repos.data.city?repos.data.city:"")
              setArea(repos.data.area?repos.data.area:"")
              setEducation(repos.data.education?repos.data.education:"")
              setLanguage(!repos.data.languages||repos.data.languages=="undefined"?"":repos.data.languages)
              setPreviousCurrent(!repos.data.currentSalary||repos.data.currentSalary=="undefined"?"":repos.data.currentSalary)
              setExpectedSalary(!repos.data.expectedSalary||repos.data.expectedSalary=="undefined"?"":repos.data.expectedSalary)
              setVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).voiceProcess:true)
              setNonVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).nonVoiceProcess:true)
              setWorkFromHome(repos.data.preferences?JSON.parse(repos.data.preferences).workFromHome:true)
              setWorkFromOffice(repos.data.preferences?JSON.parse(repos.data.preferences).workFromOffice:true)
              setPartTime(repos.data.preferences?JSON.parse(repos.data.preferences).partTime:true)
              setFullTime(repos.data.preferences?JSON.parse(repos.data.preferences).fullTime:true)
              setNightShift(repos.data.preferences?JSON.parse(repos.data.preferences).nightShift:true)
              setDayShift(repos.data.preferences?JSON.parse(repos.data.preferences).dayShift:true)
              setCompanyTransportDay(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportDay:true)
              setCompanyTransportNight(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportNight:true)
              setOwnTransport(repos.data.preferences?JSON.parse(repos.data.preferences).ownTransport:true)
              setWillingToRelocate(repos.data.preferences?JSON.parse(repos.data.preferences).willingToRelocate:true)
              if(repos.data.profilePicUrl && repos.data.profilePicUrl.length>50){
setImg("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
// console.log("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
              }
            }else props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
      }



  const handleClose = () =>{
  
   if(props.open===true){
    props.close();
   }
    if(props.openApp===true){
      resetApplicant()
      props.closeApp();
    }
   
    setOpenApplicantPopup(false);
    
    }
  
    const resetApplicant = ()=>{
        setId("");
        setName("");
        setMail("");
        setMobile("");
        setWhatsNumber("");
        setDob(new Date());
        setAddress("");
        setCity("");
        setArea("");
        setSkills("");
        setGender("");
        setExpYears(0);
        setExpMonths(0);
        setReference("");
      }

//Upload Resume to Google Drive
      const UploadToDrive = async (applicantId) =>{
     
        var file = resume //the file
        var reader = new FileReader() //this for convert to Base64 
        reader.readAsDataURL(resume) //start conversion...
        reader.onload = function (e) { //.. once finished..
          var rawLog = reader.result.split(',')[1]; //extract only thee file data part
          var dataSend = { dataReq: { data: rawLog, name:file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
         fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
            { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
            .then(res => res.json()).then((data) => {
              //See response
              console.log(data)
              RetrieveResume(data,applicantId)
              props.showAlert("Resume Uploaded Successfully","success")
      
            }).catch(err => console.log(err)) // Or Error in console
        }
      
      }

      const RetrieveResume =async (data,applicantId)=>{
       
if(applicantId){
 
        let apiURL=process.env.REACT_APP_ + "/applicants/saveResumeUrl/" + applicantId;
        let apiParams = {
            method:"POST",
            headers:{
              resumeurl:data.url,
              authtoken: localStorage.getItem("loggedintoken")
            }
      }
      fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
           
          // setResumeUrl(data.url)
          }
        })
        .catch(e => console.log(e)) 
  } 
 }


        const handleUpload = (e) =>{
        let formData = new FormData();
        formData.append("resume",e.target.files[0]);
        setResume(e.target.files[0]);
        // console.log(formData)
      }

      const checkduplicate =()=>{
        if(mobile.length>=10){
        let phone = mobile.toString().replace('-','').slice(-10);
        // console.log(phone)
        let apiURL=process.env.REACT_APP_API_URL + "/applicants/phone/" +phone;
          let apiParams = {
              method:"GET",
              
          };
           fetch(apiURL, apiParams)
          .then((res)=> (res.status===200 ? res.json() : res.text()))
          .then((repos)=> {
              if (repos.data) {
                if(user && user.accesstype== 5){
        let confirmation=window.confirm(repos.data.name+" registered with "+repos.data.mobile+". Do you want to create another registration with same number.")
        if(!confirmation){
          setMobile("")
        }else{
          return
        }}else{
          props.showAlert((repos.data.name+" registered with "+repos.data.mobile+" already!"),"error")
          setMobile("")
        }
              } 
          }) 
          .catch((err) => props.showAlert((''+err),"error"));
        }
      
        }


        //Upload Image To google Drive 


        const handleImageUpload = async (e) => {
          if (e.target.files[0].size > 71680) {
            //alert("Please choose a file with size below 70kb!");
            props.showAlert("Please choose a file with size below 70kb!","error")
            return;
          }
          handleFileSelect(e.target.files[0]);

          setProfilePic(e.target.files[0]);
         
        };

      //Upload Image
        const UploadImageToDrive = async (applicantId) =>{
     
          var file = profilePic //the file
          var reader = new FileReader() //this for convert to Base64 
          reader.readAsDataURL(profilePic) //start conversion...
          reader.onload = function (e) { //.. once finished..
            var rawLog = reader.result.split(',')[1]; //extract only thee file data part
            var dataSend = { dataReq: { data: rawLog, name:file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
           fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
              { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
              .then(res => res.json()).then((data) => {
                //See response
                
                 SaveProfilePic(data,applicantId)
                props.showAlert("Profile Pic Uploaded Successfully","success")
        
              }).catch(err => console.log(err)) // Or Error in console
          }
        
        }

  
      const SaveProfilePic = (data,applicantId)=>{
if(applicantId){

        let apiURL=process.env.REACT_APP_API_URL + "/applicants/saveProfileUrl/" + applicantId;
        let apiParams = {
            method:"POST",
            headers:{
              profilepicurl:data.url,
              authtoken: localStorage.getItem("loggedintoken")
            }
      }
      fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
           
        
          }
        })
        .catch(e => console.log(e)) 
  } 
      }


        function handleFileSelect(f) {
          var reader = new FileReader();
          // Closure to capture the file information.
          reader.onload = (function (theFile) {
            return function (e) {
              var binaryData = e.target.result;
              //Converting Binary Data to base 64
              var base64String = window.btoa(binaryData);
              //showing file converted to base64
              setImg("data:image/jpeg;base64," + base64String);
            };
          })(f);
          // Read in the image file as a data URL.
          reader.readAsBinaryString(f);
        }


        
 //functions
 const addApplicant=()=>{

  if (mobile.length === 0) {
   
    props.showAlert("Enter Mobile Number!!","error");
    return;
  }
  if (name.length === 0) {
      props.showAlert("Enter Name!!","error");
      return;
    }
    if (mail.length === 0) {
  
      props.showAlert("Enter Email!!","error");
      return;
    }
    if (gender.length === 0) {
    
      props.showAlert("Select Gender!!","error");
      return;
    }
  
   setIsLoading(true)

   let apiURL=process.env.REACT_APP_API_URL + "/applicants/addapplicant";
   let apiParams = {
       method:"POST",
       headers:{
           name: name,
           email: mail,
           mobile: mobile,
           number:whatsNumber,
           dob:Dob,
           city:city,
           area:area,
           education:education,
           preferences:JSON.stringify({
            voiceProcess:voiceProcess,
            nonVoiceProcess:nonVoiceProcess,
            workFromHome:workFromHome,
            workFromOffice:workFromOffice,
            partTime:partTime,
            fullTime:fullTime,
            nightShift:nightShift,
            dayShift:dayShift,
            companyTransportDay:companyTransportDay,
            companyTransportNight:companyTransportNight,
            ownTransport:ownTransport,
            willingToRelocate:willingToRelocate,
            }),
           languages:((language && language.length>0 && language.lastIndexOf(",")===language.length-1)?language.substring(0,language.length-1):language),
           currentsalary:previousCurrent,
           expectedsalary:expectedSalary,
           experience:(parseInt(ExpYears)*12)+parseInt(ExpMonth),
           skill:encodeURIComponent(Skills),
           gender: gender,
           jobid: jobId,
           jobtitle: jobTitle,
           companyname:companyName,
           address: encodeURIComponent(address),
           reference:encodeURIComponent(reference),
           createdbyid:user?user._id:name,
           createdbyname:user?user.name:name,
           authtoken: localStorage.getItem("loggedintoken")
       },
      // body: resume,
   };
   // console.log(apiParams)
   fetch(apiURL, apiParams)
   .then((res)=> (res.status===200 ? res.json() : res.text()))
   .then((repos)=> {
       if (repos.data) {
  
        setApplicantId(repos.data._id)
        profilePic && UploadImageToDrive(repos.data._id)
        resume && UploadToDrive(repos.data._id)
       
      
        
        setOpenApplicantPopup(false)
         setIsLoading(false)
         props.showAlert("Applicant Added Successfully","success")
        // alert("Applicant Added Successfull");
         if(user && scheduleInterview ){
           setTimeout(()=>{
             window.location.href="/jobs?applicantid="+repos.data._id;
           },1000)
         }
         else{
           setTimeout(()=>{
             sendEmailToCandidate(repos.data._id)
             window.location.reload(false);
           },1500)
         }
          
       }else{ props.showAlert(""+(repos.message || repos),"error");
       setIsLoading(false)}
   }) 
   .catch((err) =>{props.showAlert((''+err),"error")
   setIsLoading(false)})
 }

 const sendEmailToCandidate = ()=>{
  if(mail){
  let apiURL=process.env.REACT_APP_API_URL + "/users/sendemailtoapplicant";
  let apiParams = {
    method:"POST",
    headers:{
        email:mail,
        name:name,
     }
    };
   
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.message) {
       
          props.showAlert("Email Has been sent Successfully","success")
          
        }else{ props.showAlert(""+(repos.message || repos),"error");
        setIsLoading(false)
      }
  
    }) 
    .catch((err) =>{props.showAlert((''+err),"error")
    setIsLoading(false)})
  }
 }


 
 
 const searchJobByApplicantId = async ()=>{
if(id)
{
    let apiURL = process.env.REACT_APP_API_URL +"/interview/getjobsbyapplicantid/"+ id;
  
    let apiParams = {
      method:"GET",
      authtoken: localStorage.getItem("loggedintoken")
  };
  await fetch(apiURL, apiParams)
 .then((res)=> (res.status===200 ? res.json() : res.text()))
 .then((repos)=> {
     if (repos.data) {

      setJobId(repos.data[0] && repos.data[0]._id.jobId)
      setJobTitle(repos.data[0] && repos.data[0]._id.jobtitle)
      setJobApplications(repos.data)

      
     }else {
      props.showAlert(""+(repos.message || repos),"error");  
     }
 }) 
 .catch((err) => props.showAlert((''+err),"error"));}
}

const setPreference=(key,value)=>
{
switch (key) {
case "voiceProcess":
  setVoiceProcess(value)
  break;
case "nonVoiceProcess":
  setNonVoiceProcess(value)
  break;
case "workFromHome":
  setWorkFromHome(value)
  break;
case "workFromOffice":
  setWorkFromOffice(value)
  break;
  case "partTime":
    setPartTime(value)
    break;
  case "fullTime":
    setFullTime(value)
    break;
  case "nightShift":
    setNightShift(value)
    break;
  case "dayShift":
    setDayShift(value)
    break;
    case "companyTransportDay":
      setCompanyTransportDay(value)
      break;
      case "companyTransportNight":
        setCompanyTransportNight(value)
        break;
    case "ownTransport":
      setOwnTransport(value)
      break;
default:
  break;
}
}

//edit applicant
const editApplicantDetails=(id)=>{
    setIsLoading(true)
    let apiURL=process.env.REACT_APP_API_URL + "/applicants/editapplicant/"+id;
    let apiParams = {
        method:"POST",
        headers:{
            name: name,
            email: mail,
            mobile: mobile,
            number:whatsNumber,
            dob:Dob,
            city:city,
            area:area,
            education:education,
            preferences:JSON.stringify({
              voiceProcess:voiceProcess,
              nonVoiceProcess:nonVoiceProcess,
              workFromHome:workFromHome,
              workFromOffice:workFromOffice,
              partTime:partTime,
              fullTime:fullTime,
              nightShift:nightShift,
              dayShift:dayShift,
              companyTransportDay:companyTransportDay,
              companyTransportNight:companyTransportNight,
              ownTransport:ownTransport,
              willingToRelocate:willingToRelocate,
              }),
            languages:((language && language.length>0 && language.lastIndexOf(",")===language.length-1)?language.substring(0,language.length-1):language),
            currentsalary:previousCurrent,
            expectedsalary:expectedSalary,
            experience:(parseInt(ExpYears)*12)+parseInt(ExpMonth),
            skill:encodeURIComponent(Skills),
            gender: gender,
            address: encodeURIComponent(address),
            reference:encodeURIComponent(reference),
            updatedById:user?user._id:name,
            updatedByName:user?user.name:name,
            authtoken: localStorage.getItem("loggedintoken")
  
        }
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          setOpenApplicantPopup(false)
          setIsLoading(false)
         
          profilePic && UploadImageToDrive(id)
          props.showAlert("Applicant Updated Successfully","success")
          // setTimeout(()=>{
          //   if(props.applicantId){
          //     setId(props.applicantId)
        
          //     showApplicant()
      
          //    }else if(props.id){
          //     setId(props.id)
          //     showApplicantDetails(props.id)
          //    }
       
          // },2000)
          
        }else {
          props.showAlert(""+(repos.message || repos),"error");
            setIsLoading(false)
        }
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }

  const updateLanguage = (lang) =>{

    var newLanguageValue = (((language.length>0 && language.lastIndexOf(",")===language.length-1)?language.substring(0,language.length-1):language)+lang);
    setLanguage(((newLanguageValue.length>0 && newLanguageValue.indexOf(",")===0)?newLanguageValue.substring(1,newLanguageValue.length-1):newLanguageValue));
    }




  return (
   <div style={{textAlign:"center"}}>
  {window.location.pathname!=="/apply" ?<CandidateProfileTopBar />:""}
   {isMatched !== true?
   <div style={{width:"50%",left:"0%",top:!user ||(user.accesstype!=="c" && user.accesstype!=="candidate" && user.accesstype!=="recruiter" ) ?"0%":'15%',position:'fixed',height:"100%"}}>
   <img  id="kisspng-recruiter-human-resour" src="kisspng-recruiter-human-resour.png" srcset="kisspng-recruiter-human-resour.png 1x, kisspng-recruiter-human-resour@2x.png 2x" />
		
   {!user ||(user.accesstype!=="c" && user.accesstype!=="candidate" && user.accesstype!=="recruiter" ) ? <img id="bizibees" src="bizibees.png" srcset="bizibees.png 1x, bizibees@2x.png 2x" />:""}
 
   </div>:""}
 <RegisterUI mobile={isMatched} open={props.open} showAlert={props.showAlert} applicantId={props.applicantId} />
</div>
  )
  
}

export default ApplicantPopupUI
