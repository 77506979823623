import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider} from '@mui/material';
import './Applicants.css';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import CheckIcon from '@mui/icons-material/Check';
import ScheduleInterview from './ScheduleInterview';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
// import DatePicker from '@mui/lab/DatePicker'; 
// import TimePicker from '@mui/lab/TimePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import { Splitscreen } from '@mui/icons-material';
// import { id } from 'date-fns/locale';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { setSeconds } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { id } from 'date-fns/locale';
import { UserContext } from './UserContext';
import { CircularProgress } from '@mui/material';
import { Autocomplete } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import WorkPreferences from './WorkPreferences';




const ApplicantPopup = (props) => {
   
  const style2 = {
    position: 'relative',
    margin:"auto",
    height: "auto",
    minWidth: 375,
    maxWidth: 450,
    scrollY:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    };
  
    const City = [
        { label: 'Bangalore' },
      ]  
      const Reference = [
        { label: 'Apna App' },
        { label: 'Linked In' },
        { label: 'Facebook' },
        { label: 'Naukri.com' },
        { label: 'Monster.com' },
        { label: 'Quickr.com' },
        { label: 'Walk-In' },
      ]  
    const label = { inputProps: { 'aria-label': 'Schedule Interview' } };
  
    const today=() => {
      var date = new Date();
      const day = (date.getDate() < 10 ? '0' : '')+date.getDate();
      const month = (date.getMonth() + 1 < 10 ? '0' : '')+(date.getMonth() + 1);
      const year = date.getFullYear();
      return year+'-'+month+'-'+day;
    }
  // const [interview, setInterview] = useState([]);
  const [OpenShedule, setOpenScedule ]= React.useState();
  const [searchParams] = useSearchParams();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsNumber,setWhatsNumber] = useState("");
  const [gender, setGender] = useState("");
  const [experience,setExperience]=useState();
  const [address, setAddress] = useState("");
  const [ Skills,setSkills]= useState("");
  const [jobId, setJobId] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [openApplicantPopup, setOpenApplicantPopup] = React.useState(false);
  const [resume, setResume ] = useState();
  const [reference, setReference]= useState("");
  const [Dob, setDob ]= React.useState(today());
  const [ExpYears, setExpYears]= useState(0);
  const [ExpMonth, setExpMonths]= useState(0);
  const [companyName, setCompanyName] = useState("");
  const { user} = useContext(UserContext);
  const [isLoading,setIsLoading]=useState(false);
  const [city ,setCity]= useState("");
  const [area,setArea] = useState("");
  const [scheduleInterview, setScheduleInterview] = useState(true);
  const [language, setLanguage ] = useState("");
  const [previousCurrent,setPreviousCurrent ]= useState(0);
  const [expectedSalary,setExpectedSalary ] = useState(0);
  const [voiceProcess,setVoiceProcess] = useState(true)
  const [nonVoiceProcess,setNonVoiceProcess] = useState(true)
  const [workFromHome,setWorkFromHome] = useState(true)
  const [workFromOffice,setWorkFromOffice] = useState(true)
  const [partTime,setPartTime] = useState(true)
  const [fullTime,setFullTime] = useState(true)
  const [nightShift,setNightShift] = useState(true)
  const [dayShift,setDayShift] = useState(true)
  const [companyTransportDay,setCompanyTransportDay] = useState(true)
  const [companyTransportNight,setCompanyTransportNight] = useState(true)
  const [ownTransport,setOwnTransport] = useState(true)
  const [willingToRelocate,setWillingToRelocate] = useState(true)
  const [jobApplications,setJobApplications] = useState([])
  const [education,setEducation] = useState("");
  
  useEffect(()=>{
    
    setOpenApplicantPopup(props.open)

    if(props.open)
      {
    
       if(props.applicantId){
        setId(props.applicantId)
  
        showApplicant()

       }

      }

  
    },[props.open])
    
    useEffect(()=>{

      props.applicantId && Promise.all(props.applicantId).then( ()=>{
        
        searchJobByApplicantId()
        
      });
         
        
      },[id])


      useEffect(()=>{
        
       
       if(props.openApp)
         {
         setOpenApplicantPopup(props.openApp)
    if(props.id){
           setId(props.id)
           showApplicantDetails(props.id)
          }
           
        }
     
       },[props.openApp])
       
  
    const showApplicant = ()=>{
        if(props.applicantId){
        let apiURL=process.env.REACT_APP_API_URL + "/applicants/getapplicantdetails/" +props.applicantId
        let apiParams = {
            method:"GET",
            authtoken: localStorage.getItem("loggedintoken")
        };
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
            
              setName(repos.data.name?repos.data.name:"")
              setMail(repos.data.email?repos.data.email:"")
              setMobile(repos.data.mobile?repos.data.mobile:"")
              setWhatsNumber(repos.data.number?repos.data.number:"")
              setDob(repos.data.dob?repos.data.dob:today())
              setGender(repos.data.gender)
              setExperience(repos.data.experience?repos.data.experience:"")
              setSkills(repos.data.skill?repos.data.skill:"")
              setAddress(repos.data.address?repos.data.address:"")
              setReference(repos.data.reference?repos.data.reference:"")
              setCity(repos.data.city?repos.data.city:"")
              setArea(repos.data.area?repos.data.area:"")
              setEducation(repos.data.education?repos.data.education:"")
              setLanguage(!repos.data.languages||repos.data.languages=="undefined"?"":repos.data.languages)
              setPreviousCurrent(!repos.data.currentSalary||repos.data.currentSalary=="undefined"?"":repos.data.currentSalary)
              setExpectedSalary(!repos.data.expectedSalary||repos.data.expectedSalary=="undefined"?"":repos.data.expectedSalary)
              setVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).voiceProcess:true)
              setNonVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).nonVoiceProcess:true)
              setWorkFromHome(repos.data.preferences?JSON.parse(repos.data.preferences).workFromHome:true)
              setWorkFromOffice(repos.data.preferences?JSON.parse(repos.data.preferences).workFromOffice:true)
              setPartTime(repos.data.preferences?JSON.parse(repos.data.preferences).partTime:true)
              setFullTime(repos.data.preferences?JSON.parse(repos.data.preferences).fullTime:true)
              setNightShift(repos.data.preferences?JSON.parse(repos.data.preferences).nightShift:true)
              setDayShift(repos.data.preferences?JSON.parse(repos.data.preferences).dayShift:true)
              setCompanyTransportDay(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportDay:true)
              setCompanyTransportNight(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportNight:true)
              setOwnTransport(repos.data.preferences?JSON.parse(repos.data.preferences).ownTransport:true)
              setWillingToRelocate(repos.data.preferences?JSON.parse(repos.data.preferences).willingToRelocate:true)
            
            }else props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
      }
    }

    const showApplicantDetails = (id)=>{
       
        let apiURL=process.env.REACT_APP_API_URL + "/applicants/getapplicantdetails/" +id;
        let apiParams = {
            method:"GET",
            authtoken: localStorage.getItem("loggedintoken")
        };
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              setName(repos.data.name?repos.data.name:"")
              setMail(repos.data.email?repos.data.email:"")
              setMobile(repos.data.mobile?repos.data.mobile:"")
              setWhatsNumber(repos.data.number?repos.data.number:"")
              setDob(repos.data.dob?repos.data.dob:today())
              setGender(repos.data.gender)
             setExperience(repos.data.experience?repos.data.experience:"")
              setSkills(repos.data.skill?repos.data.skill:"")
              setAddress(repos.data.address?repos.data.address:"")
              setReference(repos.data.reference?repos.data.reference:"")
              setCity(repos.data.city?repos.data.city:"")
              setArea(repos.data.area?repos.data.area:"")
              setEducation(repos.data.education?repos.data.education:"")
              setLanguage(!repos.data.languages||repos.data.languages=="undefined"?"":repos.data.languages)
              setPreviousCurrent(!repos.data.currentSalary||repos.data.currentSalary=="undefined"?"":repos.data.currentSalary)
              setExpectedSalary(!repos.data.expectedSalary||repos.data.expectedSalary=="undefined"?"":repos.data.expectedSalary)
              setVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).voiceProcess:true)
              setNonVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).nonVoiceProcess:true)
              setWorkFromHome(repos.data.preferences?JSON.parse(repos.data.preferences).workFromHome:true)
              setWorkFromOffice(repos.data.preferences?JSON.parse(repos.data.preferences).workFromOffice:true)
              setPartTime(repos.data.preferences?JSON.parse(repos.data.preferences).partTime:true)
              setFullTime(repos.data.preferences?JSON.parse(repos.data.preferences).fullTime:true)
              setNightShift(repos.data.preferences?JSON.parse(repos.data.preferences).nightShift:true)
              setDayShift(repos.data.preferences?JSON.parse(repos.data.preferences).dayShift:true)
              setCompanyTransportDay(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportDay:true)
              setCompanyTransportNight(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportNight:true)
              setOwnTransport(repos.data.preferences?JSON.parse(repos.data.preferences).ownTransport:true)
              setWillingToRelocate(repos.data.preferences?JSON.parse(repos.data.preferences).willingToRelocate:true)

            }else {
            
              props.showAlert(""+(repos.message || repos),"error"); 
            } 
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
      }



  const handleClose = () =>{
  
   if(props.open===true){
    props.close();
   }
    if(props.openApp===true){
      resetApplicant()
      props.closeApp();
    }
   
    setOpenApplicantPopup(false);
    
    }
  
    const resetApplicant = ()=>{
        setId("");
        setName("");
        setMail("");
        setMobile("");
        setWhatsNumber("");
        setDob(today());
        setAddress("");
        setCity("");
        setArea("");
        setSkills("");
        setGender("");
        setExpYears(0);
        setExpMonths(0);
        setReference("");
      }

      const handleUpload = (e) =>{
        let formData = new FormData();
        formData.append("resume",e.target.files[0]);
        setResume(formData);
      }

      const checkduplicate =()=>{
        if(mobile.length>=10){
        let phone = mobile.toString().replace('-','').slice(-10);
        // console.log(phone)
        let apiURL=process.env.REACT_APP_API_URL + "/applicants/phone/" +phone;
          let apiParams = {
              method:"GET",
              authtoken: localStorage.getItem("loggedintoken")              
          };
           fetch(apiURL, apiParams)
          .then((res)=> (res.status===200 ? res.json() : res.text()))
          .then((repos)=> {
              if (repos.data) {
                if(user.accesstype== 5){
        let confirmation=window.confirm(repos.data.name+" registered with "+repos.data.mobile+". Do you want to create another registration with same number.")
        if(!confirmation){
          setMobile("")
        }else{
          return
        }}else{
          alert(repos.data.name+" registered with "+repos.data.mobile+". You Cannot add Candidate Again, kindly check with Admin before Creating!!!")
          setMobile("")
        }
              } 
          }) 
          .catch((err) => props.showAlert((''+err),"error"));
        }
      
        }

        
 //functions
 const addApplicant=()=>{

  if (mobile.length === 0) {
    alert("Enter Mobile!!");
    return;
  }
  if (name.length === 0) {
      alert("Enter Name!!");
      return;
    }
    if (mail.length === 0) {
      alert("Enter Email!!");
      return;
    }
    if (gender.length === 0) {
      alert("Select Gender");
      return;
    }
  
   setIsLoading(true)

   let apiURL=process.env.REACT_APP_API_URL + "/applicants/addapplicant";
   let apiParams = {
       method:"POST",
       headers:{
           name: name,
           email: mail,
           mobile: mobile,
           number:whatsNumber,
           dob:Dob,
           city:city,
           area:area,
           education:education,
           preferences:JSON.stringify({
            voiceProcess:voiceProcess,
            nonVoiceProcess:nonVoiceProcess,
            workFromHome:workFromHome,
            workFromOffice:workFromOffice,
            partTime:partTime,
            fullTime:fullTime,
            nightShift:nightShift,
            dayShift:dayShift,
            companyTransportDay:companyTransportDay,
            companyTransportNight:companyTransportNight,
            ownTransport:ownTransport,
            willingToRelocate:willingToRelocate,
            }),
           languages:((language && language.length>0 && language.lastIndexOf(",")===language.length-1)?language.substring(0,language.length-1):language),
           currentsalary:previousCurrent,
           expectedsalary:expectedSalary,
           experience:(parseInt(ExpYears)*12)+parseInt(ExpMonth),
           skill:encodeURIComponent(Skills),
           gender: gender,
           jobid: jobId,
           jobtitle: jobTitle,
           companyname:companyName,
           address: encodeURIComponent(address),
           reference:encodeURIComponent(reference),
           createdbyid:user?user._id:name,
           createdbyname:user?user.name:name,
           authtoken: localStorage.getItem("loggedintoken")
       },
      body: resume,
   };
   // console.log(apiParams)
   fetch(apiURL, apiParams)
   .then((res)=> (res.status===200 ? res.json() : res.text()))
   .then((repos)=> {
       if (repos.data) {
       
        setOpenApplicantPopup(false)
         setIsLoading(false)
         props.showAlert("Applicant Added Successfully","success")
        // alert("Applicant Added Successfull");
         if(user && scheduleInterview ){
           setTimeout(()=>{
             window.location.href="/jobs?applicantid="+repos.data._id;
           },1000)
         }
         else{
           setTimeout(()=>{
             sendEmailToCandidate(repos.data._id)
             window.location.reload(false);
            
           },1500)
         }
          
       }else{ props.showAlert(""+(repos.message || repos),"error");
       setIsLoading(false)}
   }) 
   .catch((err) =>{props.showAlert((''+err),"error")
   setIsLoading(false)})
 }

 const sendEmailToCandidate = ()=>{
  if(mail){
  let apiURL=process.env.REACT_APP_API_URL + "/users/sendemailtoapplicant";
  let apiParams = {
    method:"POST",
    headers:{
        email:mail,
        name:name,
        authtoken: localStorage.getItem("loggedintoken")
     }
    };
   
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.message) {
       
          props.showAlert("Email Has been sent Successfully","success")
          
        }else{ props.showAlert(""+(repos.message || repos),"error");
        setIsLoading(false)
      }
  
    }) 
    .catch((err) =>{props.showAlert((''+err),"error")
    setIsLoading(false)})
  }
 }


 const scheduleinterview = ()=>{
if(jobId==="new"){

  window.location.href="/jobs?applicantid="+id;

}else{

    props.close(jobId)
}
 }
 
 const searchJobByApplicantId = async ()=>{
if(id)
{
    let apiURL = process.env.REACT_APP_API_URL +"/interview/getjobsbyapplicantid/"+ id;
    let apiParams = {
      method:"GET",
      authtoken: localStorage.getItem("loggedintoken")
  };
  await fetch(apiURL, apiParams)
 .then((res)=> (res.status===200 ? res.json() : res.text()))
 .then((repos)=> {
     if (repos.data) {

      setJobId(repos.data[0] && repos.data[0]._id.jobId)
      setJobTitle(repos.data[0] && repos.data[0]._id.jobtitle)
      setJobApplications(repos.data)

      
     }else {
      props.showAlert(""+(repos.message || repos),"error");  
     }
 }) 
 .catch((err) => props.showAlert((''+err),"error"));}
}

const setPreference=(key,value)=>
{
switch (key) {
case "voiceProcess":
  setVoiceProcess(value)
  break;
case "nonVoiceProcess":
  setNonVoiceProcess(value)
  break;
case "workFromHome":
  setWorkFromHome(value)
  break;
case "workFromOffice":
  setWorkFromOffice(value)
  break;
  case "partTime":
    setPartTime(value)
    break;
  case "fullTime":
    setFullTime(value)
    break;
  case "nightShift":
    setNightShift(value)
    break;
  case "dayShift":
    setDayShift(value)
    break;
    case "companyTransportDay":
      setCompanyTransportDay(value)
      break;
      case "companyTransportNight":
        setCompanyTransportNight(value)
        break;
    case "ownTransport":
      setOwnTransport(value)
      break;
default:
  break;
}
}

//edit applicant
const editApplicantDetails=(id)=>{
    setIsLoading(true)
    let apiURL=process.env.REACT_APP_API_URL + "/applicants/editapplicant/"+id;
    let apiParams = {
        method:"POST",
        headers:{
            name: name,
            email: mail,
            mobile: mobile,
            number:whatsNumber,
            dob:Dob,
            city:city,
            area:area,
            education:education,
            preferences:JSON.stringify({
              voiceProcess:voiceProcess,
              nonVoiceProcess:nonVoiceProcess,
              workFromHome:workFromHome,
              workFromOffice:workFromOffice,
              partTime:partTime,
              fullTime:fullTime,
              nightShift:nightShift,
              dayShift:dayShift,
              companyTransportDay:companyTransportDay,
              companyTransportNight:companyTransportNight,
              ownTransport:ownTransport,
              willingToRelocate:willingToRelocate,
              }),
            languages:((language && language.length>0 && language.lastIndexOf(",")===language.length-1)?language.substring(0,language.length-1):language),
            currentsalary:previousCurrent,
            expectedsalary:expectedSalary,
            experience:(parseInt(ExpYears)*12)+parseInt(ExpMonth),
            skill:encodeURIComponent(Skills),
            gender: gender,
            address: encodeURIComponent(address),
            reference:encodeURIComponent(reference),
            updatedById:user?user._id:name,
            updatedByName:user?user.name:name,
            authtoken: localStorage.getItem("loggedintoken")
  
        }
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          setOpenApplicantPopup(false)
          setIsLoading(false)
          
          props.showAlert("Applicant Updated Successfully","success")
          setTimeout(()=>{
            window.location.reload(false);
       
          },2000)
          
        }else {
          props.showAlert(""+(repos.message || repos),"error");
            setIsLoading(false)
        }
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }

  const updateLanguage = (lang) =>{

    var newLanguageValue = (((language.length>0 && language.lastIndexOf(",")===language.length-1)?language.substring(0,language.length-1):language)+lang);
    setLanguage(((newLanguageValue.length>0 && newLanguageValue.indexOf(",")===0)?newLanguageValue.substring(1,newLanguageValue.length-1):newLanguageValue));
    }



  return (
    user &&((user.accesstype==="c")||(user.accesstype==""))?
    <Box sx={style2}>
    <div>
      <Typography id="modal-modal-description">
      <Container style={{padding:"0px"}}>
      <div className = "content1">
    <div style={{textAlign:"center"}}>
      <h4 className="fnt3"><b>{id.length>0?name:"ADD APPLICANT DETAILS"}</b> {props.close || props.closeApp?<Button disabled={isLoading} title="Close" variant="Standard" style={{float:"right",right:0,top:0,position:"absolute",height:28,width:5 }} onClick={()=>handleClose()} ><CloseIcon /></Button>:""}</h4> 
     </div>
     {props.applicantId && user.accesstype!=="c"?
     <div>
     <FormControl variant="outlined" sx={{width: 320 }} style={{marginTop:"10px"}} size="small">
        <InputLabel id="appliedJobLabel">Applied Job</InputLabel>
        <Select
        
          labelId="appliedJobLabel"
          id="appliedJobLabelSelect"
          
          value={jobId} onChange={(e)=> {
        
            setJobId(e.target.value); 
            setJobTitle(jobApplications.filter(job=>job.jobId===e.target.value)[0].jobtitle);   
           } } >
          {
           jobApplications && jobApplications.map(job=>  <MenuItem value={job._id.jobId}>{job._id.companyname}:{job._id.jobtitle}</MenuItem>
          
           )
        }
        <MenuItem value="new">Add New Interview</MenuItem>
        </Select> 
      </FormControl>
      <Button size='small' title="View Applicant" style={{top:"15px",padding:"0px"}} onClick={()=>scheduleinterview()}><EventIcon/></Button>
      </div>:""}
   
     <table border="0">
    <tr>
    <td colSpan={1} style={{textAlign:"center"}}>     
     <TextField id="mobile"  sx={{ width: 160,margin:"2px"}}  type="number" size="small" label="Mobile No" variant="outlined"  value={mobile} onBlur={()=>checkduplicate()}
       onChange={(e)=> {
        setMobile(e.target.value);  
    }}/> 
    
  <TextField  id="outlined-size-small"  sx={{ width: 160, margin:"2px" }}  size="small" label="Name" variant="outlined"  value={name || ""} onChange={(e)=> {
      setName(e.target.value);
  }}/>
    </td>
    </tr>
    <tr><td colSpan={1} style={{textAlign:"center"}}>
   <FormControl variant="outlined" sx={{minWidth: 160,margin:"2px"}} size="small" >
    <InputLabel id="demo-simple-select-label"  >Gender</InputLabel>
    <Select
      labelId="demo-simple-select-labell"
      id="demo-simple-select"
      sx={{minWidth: 160}}
      value={gender} onChange={(e)=> {
      
        setGender(e.target.value);   

       } } >
         <MenuItem selected disabled>
       
      </MenuItem>
    
      <MenuItem value="male">Male</MenuItem>
      <MenuItem value="female">Female</MenuItem>
      <MenuItem value="others">Others</MenuItem>
    </Select>
  </FormControl> 

  <TextField id="outlined-basic" sx={{width: 160,margin:"2px" }} size="small" label="email" variant="outlined"  value={mail || ""} onChange={(e)=> {
    setMail(e.target.value);     
  }}/> </td></tr>
    <tr><td colSpan={1} style={{textAlign:"center"}}>
        <TextField  id="outlined-basic" sx={{width: 160,margin:"2px"}}  size="small" label="Whatsapp No" variant="outlined"  value={whatsNumber} onChange={(e)=> {
        setWhatsNumber(e.target.value)  }}/>
      
       <input type='date'
      style={{width: 160,margin:"2px" }}
      label="DOB"
      value={Dob}
      onChange={(e) => {
      setDob(e.target.value);
    }}
    />

</td></tr>
<tr><td colSpan={1} style={{textAlign:"center"}}>
Experience : 
  <TextField  sx={{ m: 0.5, width: '10ch' }} label="Years" variant="outlined" size="small" value={ExpYears} onChange={(e)=> {
      setExpYears(e.target.value);
    }}/>

  <TextField  sx={{ m:0.5, width: '10ch' }} label="Months" variant="outlined" size="small" value={ExpMonth} onChange={(e)=> {
      
      setExpMonths(e.target.value);
    }}/>

</td></tr>
<tr>
<td colSpan={1} style={{textAlign:"center"}}>

    <FormControl variant="outlined" sx={{minWidth: 160,margin:"2px"}} size="small" >
    <Autocomplete
      disablePortal
      id="txtCity"
      options={City}
      value={city || ""}
      onChange={(event,value)=>setCity(value.label)}
      renderInput={(params) => <TextField {...params}  
          sx={{width: 160,margin:"2px"}}
          size="small" label="City" 
          value={city || ""}
          onChange={(e)=> {
            setCity(e.target.value);
          }}  />}
         /> 
         </FormControl>
         <TextField  id="outlined-basic" sx={{width: 160,margin:"2px"}}  size="small" label="Area" variant="outlined"  value={area} onChange={(e)=> {
            setArea(e.target.value)  }}/>

     </td></tr>
   <tr><td style={{textAlign:"center"}}>
   <TextField 
      sx={{width: "350px",marginTop:"2px", }}  id="outlined-size-small"  size="small" label="Education" variant="outlined"  value={education} onChange={(e)=> {
      setEducation(e.target.value)
       }} /> 
   </td></tr>
        <tr><td style={{textAlign:"center"}}>
        <WorkPreferences type={props.type} preferences={{voiceProcess,nonVoiceProcess,fullTime,partTime,workFromOffice,workFromHome,dayShift,nightShift,companyTransportDay,companyTransportNight,ownTransport}} setPreference={(key,value)=>setPreference(key,value)}/>
    
        <input type="checkbox" id="chkWillingtorelocate" name="chkWillingtorelocate" checked={willingToRelocate} onChange={()=>setWillingToRelocate(!willingToRelocate)}/>
        &nbsp;
        <label  for="chkWillingtorelocate" style={{color:willingToRelocate?"red":"black"}}>Willing To Relocate</label>
        </td></tr>

    <tr>
    <td colSpan={2} style={{ textAlign: "center" }}>
      <TextField 
      sx={{width: "350px",marginTop:"2px", }}  id="outlined-size-small"  size="small" label="Languages" variant="outlined"  value={language} onChange={(e)=> {
          setLanguage(e.target.value.length>0&&e.target.value.slice(0,1)===","?language.substring(1,e.target.value.length):e.target.value);

 }} /> 
       <div style={{textAlign:"center",fontSize:"small",marginTop:"3px"}}>
      
    {(language||"").includes("English")?"":  <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",English,")}>English</button>}
    {(language||"").includes("Hindi")?"":   <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Hindi,")}>Hindi</button>}
    {(language||"").includes("Kannada")?"":   <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Kannada,")}>Kannada</button>}
    {(language||"").includes("Urdu")?"":   <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Urdu,")}>Urdu</button>}
    {(language||"").includes("Tamil")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Tamil,")}>Tamil</button>}
    {(language||"").includes("Telugu")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Telugu,")}>Telugu</button>}
    {(language||"").includes("Malayalam")?"":     <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Malayalam,")}>Malayalam</button>}
    {(language||"").includes("Marathi")?"":     <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Marathi,")}>Marathi</button>}
    {(language||"").includes("Punjabi")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Punjabi,")}>Punjabi</button>}
    {(language||"").includes("Bangla")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Bangla,")}>Bangla</button>}
    {(language||"").includes("Arabic")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Arabic,")}>Arabic</button>}
    {(language||"").includes("French")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",French,")}>French</button>}
    {(language||"").includes("Spanish")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Spanish,")}>Spanish</button>}
    {(language||"").includes("Russian")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Russian,")}>Russian</button>}
    <button style={{margin:"2px",border:"1px solid black", padding:"2px 6px"}} onClick={e=>setLanguage("")}>Clear</button>
      <br/></div> 
    </td>
  </tr>
  <tr><td colSpan={1} style={{textAlign:"center"}}>
  <TextField 
    sx={{width: "160px",margin:"2px" }}  id="outlined-size-small"  size="small" label="Current Salary" variant="outlined"  value={previousCurrent} onChange={(e)=> {
         setPreviousCurrent(e.target.value)

 }} />
   <TextField 
    sx={{width: "160px",margin:"2px" }}  id="outlined-size-small"  size="small" label="Expected Salary" variant="outlined"  value={expectedSalary} onChange={(e)=> {
         setExpectedSalary(e.target.value)
 }} /> </td></tr>
    
     <tr><td colSpan={1} style={{textAlign:"center"}}>
      <TextField
      id="outlined-multiline-flexible"
      sx={{minWidth: 350 }}
      size="small"
      label="Address"
      multiline
      rows={2} value={address || ""} 
      onChange={(e)=> {
      setAddress(e.target.value);
    }} />
    </td></tr>
    <tr><td colSpan={1} style={{textAlign:"center"}}>
       <TextField  id="outlined-basic-normal" multiline rows={2}  sx={{minWidth: 350 }} size="small" label="Skills" variant="outlined"  value={Skills || ""} onChange={(e)=> {
    setSkills(e.target.value);     
  }}/>
  </td></tr>

{id.length>0?null:
     <tr><td colSpan={1} style={{textAlign:"center"}}>
     <InputLabel  >Resume Upload : </InputLabel>
     
     <input  id="myFile" type="file" placeholder="Upload Resume"  name="Resume" onChange={(e) => handleUpload(e)}/>
     </td></tr>
   }

 <tr><td colSpan={1} style={{textAlign:"center"}}>
  <Autocomplete
  disablePortal
  id="txtReference"
  freeSolo
  options={Reference}
  sx={{ width: 300,marginLeft:"30px"}}
  value={reference || ""}
  onChange={(event,value)=>setReference(value.label)}
  renderInput={(params) => <TextField {...params}  
    
      sx={{minWidth: 300,marginLeft:"30px" }}
      size="small" label="Reference"
      value={reference || ""}
      onChange={(e)=> {
        setReference(e.target.value); 
      }}  />}
     /> 
     </td></tr>
    {user? <Checkbox {...label} checked={scheduleInterview} onChange={(e)=>setScheduleInterview(e.target.checked)} />:""}{user?<span>Schedule Interview</span>:""}
    
     
    <div style={{textAlign:"center"}}>
 
  <Button disabled={isLoading} type="reset" title={"Edit Add "}  variant="contained" onClick={()=>id.length>0?editApplicantDetails(id):addApplicant()}>{id.length>0?"Edit ":"Add Applicant "}</Button> &nbsp; &nbsp;
  &nbsp; &nbsp;{isLoading ? <CircularProgress/>:""}
 </div>
 </table>
</div>
 
  </Container> 
      </Typography>  
       </div>
       </Box>
       :
    <Modal
    open={openApplicantPopup}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    sx={{overflow:'scroll',textAlign:"center"}}
    >
  
    <Box sx={style2}>
    <div>
      <Typography id="modal-modal-description">
      <Container style={{padding:"0px"}}>
      <div className = "content1">
    <div style={{textAlign:"center"}}>
      <h4 className="fnt3"><b>{id.length>0?name:"ADD APPLICANT DETAILS"}</b> {props.close || props.closeApp?<Button disabled={isLoading} title="Close" variant="Standard" style={{float:"right",right:0,top:0,position:"absolute",height:28,width:5 }} onClick={()=>handleClose()} ><CloseIcon /></Button>:""}</h4> 
     </div>
     {props.applicantId?
     <div>
     <FormControl variant="outlined" sx={{width: 320 }} style={{marginTop:"10px"}} size="small">
        <InputLabel id="appliedJobLabel">Applied Job</InputLabel>
        <Select
          labelId="appliedJobLabel"
          id="appliedJobLabelSelect"
          
          value={jobId} onChange={(e)=> {
            console.log(e.target.value)
            if(e==="new"){

window.location.href="/jobs?applicantid="+id;

}else{
            setJobId(e.target.value); 
            setJobTitle(jobApplications.filter(job=>job.jobId===e.target.value)[0].jobtitle); }  
           } } >
          {
           jobApplications && jobApplications.map(job=>  <MenuItem value={job._id.jobId}>{job._id.companyname}:{job._id.jobtitle}</MenuItem>
          
           )
        }
        <MenuItem value="new">Add New Interview</MenuItem>
        </Select> 
      </FormControl>
      <Button size='small' title="View Applicant" style={{top:"15px",padding:"0px"}} onClick={()=>scheduleinterview()}><EventIcon/></Button>
      </div>:""}
   
     <table border="0">
    <tr>
    <td colSpan={1} style={{textAlign:"center"}}>     
     <TextField id="mobile"  sx={{ width: 160,margin:"2px"}}  type="number" size="small" label="Mobile No" variant="outlined"  value={mobile} onBlur={()=>checkduplicate()}
       onChange={(e)=> {
        setMobile(e.target.value);  
    }}/> 
    
  <TextField  id="outlined-size-small"  sx={{ width: 160, margin:"2px" }}  size="small" label="Name" variant="outlined"  value={name || ""} onChange={(e)=> {
      setName(e.target.value);
  }}/>
    </td>
    </tr>
    <tr><td colSpan={1} style={{textAlign:"center"}}>
   <FormControl variant="outlined" sx={{minWidth: 160,margin:"2px"}} size="small" >
    <InputLabel id="demo-simple-select-label"  >Gender</InputLabel>
    <Select
      labelId="demo-simple-select-labell"
      id="demo-simple-select"
      sx={{minWidth: 160}}
      value={gender} onChange={(e)=> {
      
        setGender(e.target.value);   

       } } >
         <MenuItem selected disabled>
       
      </MenuItem>
      <MenuItem value="male">Male</MenuItem>
      <MenuItem value="female">Female</MenuItem>
      <MenuItem value="others">Others</MenuItem>
    </Select>
  </FormControl> 

  <TextField id="outlined-basic" sx={{width: 160,margin:"2px" }} size="small" label="email" variant="outlined"  value={mail || ""} onChange={(e)=> {
    setMail(e.target.value);     
  }}/> </td></tr>
    <tr><td colSpan={1} style={{textAlign:"center"}}>
        <TextField  id="outlined-basic" sx={{width: 160,margin:"2px"}}  size="small" label="Whatsapp No" variant="outlined"  value={whatsNumber} onChange={(e)=> {
        setWhatsNumber(e.target.value)  }}/>
      
       <input type='date'
      style={{width: 160,margin:"2px" }}
      label="DOB"
      inputFormat="MM/dd/yyyy"
      value={Dob}
      onChange={(e) => {
      setDob(e.target.value);
    }}
      renderInput={(params) => <TextField sx={{width: 160,margin:"2px"}} size="small" {...params} />}
    />

</td></tr>
<tr><td colSpan={1} style={{textAlign:"center"}}>
Experience : 
  <TextField  sx={{ m: 0.5, width: '10ch' }} label="Years" variant="outlined" size="small" value={ExpYears} onChange={(e)=> {
      setExpYears(e.target.value);
    }}/>

  <TextField  sx={{ m:0.5, width: '10ch' }} label="Months" variant="outlined" size="small" value={ExpMonth} onChange={(e)=> {
      
      setExpMonths(e.target.value);
    }}/>

</td></tr>
<tr>
<td colSpan={1} style={{textAlign:"center"}}>

    <FormControl variant="outlined" sx={{minWidth: 160,margin:"2px"}} size="small" >
    <Autocomplete
      disablePortal
      id="txtCity"
      options={City}
      value={city || ""}
      onChange={(event,value)=>setCity(value.label)}
      renderInput={(params) => <TextField {...params}  
          sx={{width: 160,margin:"2px"}}
          size="small" label="City" 
          value={city || ""}
          onChange={(e)=> {
            setCity(e.target.value);
          }}  />}
         /> 
         </FormControl>
         <TextField  id="outlined-basic" sx={{width: 160,margin:"2px"}}  size="small" label="Area" variant="outlined"  value={area} onChange={(e)=> {
            setArea(e.target.value)  }}/>

     </td></tr>
        <tr><td style={{textAlign:"center"}}>
        <TextField 
      sx={{width: "350px",marginTop:"2px", }}  id="outlined-size-small"  size="small" label="Education" variant="outlined"  value={education} onChange={(e)=> {
      setEducation(e.target.value)

       }} /> 
        </td></tr>
        <tr><td style={{textAlign:"center"}}>
        <WorkPreferences type={props.type} preferences={{voiceProcess,nonVoiceProcess,fullTime,partTime,workFromOffice,workFromHome,dayShift,nightShift,companyTransportDay,companyTransportNight,ownTransport}} setPreference={(key,value)=>setPreference(key,value)}/>
     
        <input type="checkbox" id="chkWillingtorelocate" name="chkWillingtorelocate" checked={willingToRelocate} onChange={()=>setWillingToRelocate(!willingToRelocate)}/>
        &nbsp;
        <label  for="chkWillingtorelocate" style={{color:willingToRelocate?"red":"black"}}>Willing To Relocate</label>
        </td></tr>

    <tr>
    <td colSpan={2} style={{ textAlign: "center" }}>
      <TextField 
      sx={{width: "350px",marginTop:"2px", }}  id="outlined-size-small"  size="small" label="Languages" variant="outlined"  value={language} onChange={(e)=> {
          setLanguage(e.target.value.length>0&&e.target.value.slice(0,1)===","?language.substring(1,e.target.value.length):e.target.value);

 }} /> 
       <div style={{textAlign:"center",fontSize:"small",marginTop:"3px"}}>
      
    {(language||"").includes("English")?"":  <button style={{margin:"2px"}} onClick={e=>updateLanguage(",English,")}>English</button>}
    {(language||"").includes("Hindi")?"":   <button style={{margin:"2px"}} onClick={e=>updateLanguage(",Hindi,")}>Hindi</button>}
    {(language||"").includes("Kannada")?"":   <button style={{margin:"2px"}} onClick={e=>updateLanguage(",Kannada,")}>Kannada</button>}
    {(language||"").includes("Urdu")?"":   <button style={{margin:"2px"}} onClick={e=>updateLanguage(",Urdu,")}>Urdu</button>}
    {(language||"").includes("Tamil")?"":    <button style={{margin:"2px"}} onClick={e=>updateLanguage(",Tamil,")}>Tamil</button>}
    {(language||"").includes("Telugu")?"":    <button style={{margin:"2px"}} onClick={e=>updateLanguage(",Telugu,")}>Telugu</button>}
    {(language||"").includes("Malayalam")?"":     <button style={{margin:"2px"}} onClick={e=>updateLanguage(",Malayalam,")}>Malayalam</button>}
    {(language||"").includes("Marathi")?"":     <button style={{margin:"2px"}} onClick={e=>updateLanguage(",Marathi,")}>Marathi</button>}
    {(language||"").includes("Punjabi")?"":    <button style={{margin:"2px"}} onClick={e=>updateLanguage(",Punjabi,")}>Punjabi</button>}
    {(language||"").includes("Bangla")?"":    <button style={{margin:"2px"}} onClick={e=>updateLanguage(",Bangla,")}>Bangla</button>}
    {(language||"").includes("Arabic")?"":    <button style={{margin:"2px"}} onClick={e=>updateLanguage(",Arabic,")}>Arabic</button>}
    {(language||"").includes("French")?"":    <button style={{margin:"2px"}} onClick={e=>updateLanguage(",French,")}>French</button>}
    {(language||"").includes("Spanish")?"":    <button style={{margin:"2px"}} onClick={e=>updateLanguage(",Spanish,")}>Spanish</button>}
    {(language||"").includes("Russian")?"":    <button style={{margin:"2px"}} onClick={e=>updateLanguage(",Russian,")}>Russian</button>}
    <button style={{margin:"2px"}} onClick={e=>setLanguage("")}>Clear</button>
      <br/></div> 
    </td>
  </tr>
  <tr><td colSpan={1} style={{textAlign:"center"}}>
  <TextField 
    sx={{width: "160px",margin:"2px" }}  id="outlined-size-small"  size="small" label="Current Salary" variant="outlined"  value={previousCurrent} onChange={(e)=> {
         setPreviousCurrent(e.target.value)

 }} />
   <TextField 
    sx={{width: "160px",margin:"2px" }}  id="outlined-size-small"  size="small" label="Expected Salary" variant="outlined"  value={expectedSalary} onChange={(e)=> {
         setExpectedSalary(e.target.value)
 }} /> </td></tr>
    
     <tr><td colSpan={1} style={{textAlign:"center"}}>
      <TextField
      id="outlined-multiline-flexible"
      sx={{minWidth: 350 }}
      size="small"
      label="Address"
      multiline
      rows={2} value={address || ""} 
      onChange={(e)=> {
      setAddress(e.target.value);
    }} />
    </td></tr>
    <tr><td colSpan={1} style={{textAlign:"center"}}>
       <TextField  id="outlined-basic-normal" multiline rows={2}  sx={{minWidth: 350 }} size="small" label="Skills" variant="outlined"  value={Skills || ""} onChange={(e)=> {
    setSkills(e.target.value);     
  }}/>
  </td></tr>

{id.length>0?null:
     <tr><td colSpan={1} style={{textAlign:"center"}}>
     <InputLabel  >Resume Upload : </InputLabel>
     
     <input  id="myFile" type="file" placeholder="Upload Resume"  name="Resume" onChange={(e) => handleUpload(e)}/>
     </td></tr>
   }

 <tr><td colSpan={1} style={{textAlign:"center"}}>
  <Autocomplete
  disablePortal
  id="txtReference"
  freeSolo
  options={Reference}
  sx={{ width: 300,marginLeft:"30px"}}
  value={reference || ""}
  onChange={(event,value)=>setReference(value.label)}
  renderInput={(params) => <TextField {...params}  
    
      sx={{minWidth: 300,marginLeft:"30px" }}
      size="small" label="Reference"
      value={reference || ""}
      onChange={(e)=> {
        setReference(e.target.value); 
      }}  />}
     /> 
     </td></tr>
    {user? <Checkbox {...label} checked={scheduleInterview} onChange={(e)=>setScheduleInterview(e.target.checked)} />:""}{user?<span>Schedule Interview</span>:""}
    
     
    <div style={{textAlign:"center"}}>
 
  <Button disabled={isLoading} type="reset" title={"Edit Add "}  variant="contained" onClick={()=>id.length>0?editApplicantDetails(id):addApplicant()}>{id.length>0?"Edit ":"Add Applicant "}</Button> &nbsp; &nbsp;
  &nbsp; &nbsp;{isLoading ? <CircularProgress/>:""}
 </div>
 </table>
</div>
 
  </Container> 
      </Typography>  
       </div>
    </Box>
  </Modal>
  
  
  )
  
}

export default ApplicantPopup
